import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const Counter = (props) => {
    const [time, setTime] = useState();
    const [status, setStatus] = useState(true);
    const [timerObj] = useState({ timer: false });
    let startTimeObj = new Date().getTime();
    let endTimeObj = startTimeObj + (5 * 60000);

    const handleTimer = (endTime) => {
        const now = new Date().getTime();
        const distance = endTime - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const obj = {
            days,
            hours,
            minutes,
            seconds,
        };

        setTime(obj);

        if (distance > 0) {
            timerObj.timer = setTimeout(() => handleTimer(endTime), 1000);
        } else {
            setStatus(false);
        }
    };

    useEffect(() => {
        if (timerObj.timer) {
            clearTimeout(timerObj.timer);
        }

        handleTimer(endTimeObj);
        /* eslint-disable */
    }, []);

    const handleResend = () => {
        props.handleResend();
        setStatus(true);
        startTimeObj = new Date().getTime();
        endTimeObj = startTimeObj + (5 * 60000);
        handleTimer(endTimeObj);
    };

    return (
        props.text && props.text !== ''
            ? <p>
                {status
                    ? <>
                        {props.text}
                        <span>
                            {time && time.minutes ? time.minutes + ':' : '00:'}
                            {time && time.seconds ? time.seconds : '00'}
                        </span>
                    </>
                    : <>
                        {props.text}{' 00:00 '}
                        {' | '}
                        <p onClick={handleResend}>{variables[props.lang].resend}</p>
                    </>}
            </p>
            : <div className="otp_content">
                {status
                    ? <>
                        {variables[props.lang].otp_valid}
                        <span>
                        {time && time.minutes ? time.minutes + ':' : '00:'}
                            {time && time.seconds ? time.seconds : '00'}
                    </span>
                        {' | '}
                        <p className="disable">{variables[props.lang].resend}</p>
                    </>
                    : <>
                        {variables[props.lang].otp_valid}
                        <span>{' 00:00 '}</span>
                        {' | '}
                        <p onClick={handleResend}>{variables[props.lang].resend}</p>
                    </>}
            </div>
    );
};

Counter.propTypes = {
    handleResend: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    text: PropTypes.string,
};

export default Counter;
