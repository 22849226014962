import withRouter from '../../../components/WithRouter';
import { hideSupportConfirmDialog } from '../../../actions/buyTicket';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog } from '@mui/material';
import React from 'react';
import variables from '../../../utils/variables';
import { ReactComponent as SuccessIcon } from '../../../assets/all/success.svg';
import '../../Buyticket/ConfirmDialog/index.css';

const ConfirmDialog = (props) => {
    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog confirm_dialog"
            open={props.open}
            onClose={props.hideSupportConfirmDialog}>
            <div className="ticket_info order_confirmed">
                <SuccessIcon/>
                <p>{variables[props.lang]['thank_you_contacting']}</p>
                <div className="place_order">
                    <Button
                        onClick={props.hideSupportConfirmDialog}>
                        {variables[props.lang].close}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    hideSupportConfirmDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.buyTicket.supportConfirmDialog.open,
    };
};

const actionToProps = {
    hideSupportConfirmDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(ConfirmDialog));
