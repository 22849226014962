import React from 'react';
import './index.css';
import { IconButton } from '@mui/material';
import { ReactComponent as SupportClose } from '../../assets/all/close.svg';
import { ReactComponent as SupportIcon } from '../../assets/all/support.svg';
import * as PropTypes from 'prop-types';
import { hideSupportPopover, showSupportPopover } from '../../actions/buyTicket';
import { connect } from 'react-redux';
import SupportPopover from './SupportPopover';
import ConfirmDialog from './SupportPopover/ConfirmDialog';

const Support = (props) => {
    return (
        <>
            {props.supportPopover
                ? <IconButton
                    className="support_close"
                    onClick={props.hideSupportPopover}>
                    <SupportClose/>
                </IconButton>
                : <IconButton
                    className="support_icon"
                    onClick={props.showSupportPopover}>
                    <SupportIcon/>
                </IconButton>}
            <SupportPopover/>
            <ConfirmDialog/>
        </>
    );
};

Support.propTypes = {
    hideSupportPopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    showSupportPopover: PropTypes.func.isRequired,
    supportPopover: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        supportPopover: state.buyTicket.supportPopover.open,
    };
};

const actionToProps = {
    showSupportPopover,
    hideSupportPopover,
};

export default connect(stateToProps, actionToProps)(Support);
