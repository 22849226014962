export const BUY_TICKETS_EXPANSION_SET = 'BUY_TICKETS_EXPANSION_SET';
export const NO_OF_TICKETS_SET = 'NO_OF_TICKETS_SET';
export const TICKETS_DATA_SET = 'TICKETS_DATA_SET';
export const FULL_NAME_SET = 'FULL_NAME_SET';
export const EMAIL_ADDRESS_SET = 'EMAIL_ADDRESS_SET';
export const OTP_VALUE_SET = 'OTP_VALUE_SET';
export const OTP_VERIFY_SET = 'OTP_VERIFY_SET';
export const PAYMENT_METHOD_SET = 'PAYMENT_METHOD_SET';

export const CONFIRM_DIALOG_SHOW = 'CONFIRM_DIALOG_SHOW';
export const CONFIRM_DIALOG_HIDE = 'CONFIRM_DIALOG_HIDE';

export const CONFIRM_DIALOG_SUCCESS_SET = 'CONFIRM_DIALOG_SUCCESS_SET';

export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_IN_PROGRESS = 'CREATE_ORDER_IN_PROGRESS';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';

export const MY_ORDER_FETCH_IN_PROGRESS = 'MY_ORDER_FETCH_IN_PROGRESS';
export const MY_ORDER_FETCH_SUCCESS = 'MY_ORDER_FETCH_SUCCESS';
export const MY_ORDER_FETCH_ERROR = 'MY_ORDER_FETCH_ERROR';

export const CLEAR_CREATE_ORDER = 'CLEAR_CREATE_ORDER';

export const FETCH_TICKET_TYPE_IN_PROGRESS = 'FETCH_TICKET_TYPE_IN_PROGRESS';
export const FETCH_TICKET_TYPE_SUCCESS = 'FETCH_TICKET_TYPE_SUCCESS';
export const FETCH_TICKET_TYPE_ERROR = 'FETCH_TICKET_TYPE_ERROR';

export const TIME_OUT_DIALOG_SHOW = 'TIME_OUT_DIALOG_SHOW';
export const TIME_OUT_DIALOG_HIDE = 'TIME_OUT_DIALOG_HIDE';

export const COUPON_CODE_SET = 'COUPON_CODE_SET';
export const COUPON_CODE_REMOVE = 'COUPON_CODE_REMOVE';
export const COUPON_CODE_DATA_FETCH_IN_PROGRESS = 'COUPON_CODE_DATA_FETCH_IN_PROGRESS';
export const COUPON_CODE_DATA_FETCH_SUCCESS = 'COUPON_CODE_DATA_FETCH_SUCCESS';
export const COUPON_CODE_DATA_FETCH_ERROR = 'COUPON_CODE_DATA_FETCH_ERROR';

export const STRIP_SECRET_FETCH_IN_PROGRESS = 'STRIP_SECRET_FETCH_IN_PROGRESS';
export const STRIP_SECRET_FETCH_SUCCESS = 'STRIP_SECRET_FETCH_SUCCESS';
export const STRIP_SECRET_FETCH_ERROR = 'STRIP_SECRET_FETCH_ERROR';

export const SUPPORT_POPOVER_SHOW = 'SUPPORT_POPOVER_SHOW';
export const SUPPORT_POPOVER_HIDE = 'SUPPORT_POPOVER_HIDE';

export const CONFIRM_SUPPORT_DIALOG_SHOW = 'CONFIRM_SUPPORT_DIALOG_SHOW';
export const CONFIRM_SUPPORT_DIALOG_HIDE = 'CONFIRM_SUPPORT_DIALOG_HIDE';

export const SUPPORT_NAME_SET = 'SUPPORT_NAME_SET';
export const SUPPORT_EMAIL_SET = 'SUPPORT_EMAIL_SET';
export const SUPPORT_PURPOSE_SET = 'SUPPORT_PURPOSE_SET';
export const SUPPORT_MESSAGE_SET = 'SUPPORT_MESSAGE_SET';

export const SUPPORT_IN_PROGRESS = 'SUPPORT_IN_PROGRESS';
export const SUPPORT_SUCCESS = 'SUPPORT_SUCCESS';
export const SUPPORT_ERROR = 'SUPPORT_ERROR';
