import {
    CAPTCHA_VERIFY_CLEAR,
    CAPTCHA_VERIFY_ERROR,
    CAPTCHA_VERIFY_IN_PROGRESS,
    CAPTCHA_VERIFY_SUCCESS,
} from '../constants/captcha';

const captcha = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAPTCHA_VERIFY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAPTCHA_VERIFY_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CAPTCHA_VERIFY_ERROR:
    case CAPTCHA_VERIFY_CLEAR:
        return {
            ...state,
            inProgress: false,
            value: {},
        };
    default:
        return state;
    }
};

export default captcha;
