import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import { setSupportPurpose } from '../../../actions/buyTicket';
import SelectField from '../../../components/SelectField';

const PurposeSelectField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    const options = [{
        name: 'Payment Issue',
        label: 'Payment Issue',
    }, {
        name: 'Request for Ticket cancellation',
        label: 'Request for Ticket cancellation',
    }, {
        name: 'Report Issue',
        label: 'Report Issue',
    }, {
        name: 'Collaboration',
        label: 'Collaboration',
    }, {
        name: 'Sponsorship',
        label: 'Sponsorship',
    }, {
        name: 'Others',
        label: 'Others',
    }];

    return (
        <SelectField
            className="purpose_field"
            id="combo-box-demo purpose"
            items={options}
            name={variables[props.lang]['select_purpose']}
            options={options}
            placeholder={variables[props.lang]['select_purpose']}
            value={props.value} onChange={(event) => handleChange(event)}/>
    );
};

PurposeSelectField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.buyTicket.supportPurpose,
    };
};

const actionsToProps = {
    onChange: setSupportPurpose,
};

export default connect(stateToProps, actionsToProps)(PurposeSelectField);
