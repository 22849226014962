import React from 'react';
import { Button, Popover } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideProfilePopover, hideSideBar } from '../../actions/navBar';
import { ReactComponent as RightArrow } from '../../assets/all/right-arrow.svg';
import { setDisconnect } from '../../actions/account/wallet';
import withRouter from '../../components/WithRouter';
import { setTabValue } from '../../actions/myAccount';
import { ReactComponent as DownArrow } from '../../assets/all/down-arrow.svg';

const ProfilePopover = (props) => {
    const disconnect = () => {
        if (props.show) {
            props.hideSideBar();
            document.body.style.overflow = null;
        }

        props.setDisconnect();
        localStorage.clear();
        props.router.navigate('/buyTickets');
        props.onClose();
    };

    const handleTab = (newValue) => {
        if (props.show) {
            props.hideSideBar();
            document.body.style.overflow = null;
        }

        props.router.navigate('/myAccount');
        props.setTabValue(newValue);
        props.onClose();
    };

    return (
        <>
            <Popover
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className="profile_popover"
                open={props.open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={props.onClose}>
                <div className="profile_list">
                    <Button
                        className="profile_button">
                        {props.profileImage
                            ? <img alt="profile" className="profile_image" src={props.profileImage}/>
                            : null}
                        <div className="profile_details">
                            <span>{props.profileDetails && props.profileDetails.name
                                ? props.profileDetails.name : 'Full Name'}</span>
                            <span title={props.profileDetails && props.profileDetails.email_address}>
                                {props.profileDetails && props.profileDetails.email_address}
                            </span>
                        </div>
                        <DownArrow/>
                    </Button>
                    <Button onClick={() => handleTab('tickets')}>
                        <span>Tickets</span>
                        <RightArrow/>
                    </Button>
                    {/* <Button onClick={() => handleTab('orders')}> */}
                    {/*    <span>NFTs</span> */}
                    {/*    <RightArrow /> */}
                    {/* </Button> */}
                    <Button onClick={() => handleTab('orders')}>
                        <span>Orders</span>
                        <RightArrow/>
                    </Button>
                    <Button onClick={disconnect}>
                        <span>Disconnect</span>
                    </Button>
                </div>
            </Popover>
        </>
    );
};

ProfilePopover.propTypes = {
    hideSideBar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileImage: PropTypes.any.isRequired,
    profileImageInProgress: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }).isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setTabValue: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.navBar.profilePopover.open,
        anchorEl: state.navBar.profilePopover.anchorEl,
        profileImage: state.myAccount.profileImage.value,
        profileImageInProgress: state.myAccount.profileImage.inProgress,
        profileDetails: state.myAccount.profileDetails.value,
        show: state.navBar.show,
    };
};

const actionToProps = {
    hideSideBar,
    onClose: hideProfilePopover,
    setDisconnect,
    setTabValue,
};

export default withRouter(connect(stateToProps, actionToProps)(ProfilePopover));
