import { combineReducers } from 'redux';
import {
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    QR_CODE_FETCH_ERROR,
    QR_CODE_FETCH_IN_PROGRESS,
    QR_CODE_FETCH_SUCCESS,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS,
    TAB_VALUE_SET,
    TICKET_CANCEL_DIALOG_HIDE,
    TICKET_CANCEL_DIALOG_SHOW,
    TICKET_CANCEL_ERROR,
    TICKET_CANCEL_IN_PROGRESS,
    TICKET_CANCEL_SUCCESS,
    TICKET_DOWNLOAD_ERROR,
    TICKET_DOWNLOAD_IN_PROGRESS,
    TICKET_DOWNLOAD_SUCCESS,
    TICKETS_FETCH_ERROR,
    TICKETS_FETCH_IN_PROGRESS,
    TICKETS_FETCH_SUCCESS,
    UPDATE_NAME_SET,
    UPDATE_NAME_VALUE_SET,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_IMAGE_ERROR,
    UPDATE_PROFILE_IMAGE_IN_PROGRESS,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IN_PROGRESS,
    UPDATE_PROFILE_SUCCESS,
} from '../constants/myAccount';
import { DISCONNECT_SET } from '../constants/wallet';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../config';

const tabValue = (state = 'tickets', action) => {
    switch (action.type) {
    case TAB_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

const updateName = (state = false, action) => {
    switch (action.type) {
    case UPDATE_NAME_SET:
        return action.value;

    default:
        return state;
    }
};

const updateNameValue = (state = '', action) => {
    switch (action.type) {
    case UPDATE_NAME_VALUE_SET:
        return action.value;
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return action.value && action.value.name ? action.value.name : '';

    default:
        return state;
    }
};

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
    case UPDATE_PROFILE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_DETAILS_FETCH_ERROR:
    case UPDATE_PROFILE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const orders = (state = {
    inProgress: false,
    value: [],
    total: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ORDERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ORDERS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case ORDERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const tickets = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case TICKETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKETS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                total: action.total,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                total: action.total,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case TICKETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
            total: null,
        };
    default:
        return state;
    }
};

const cancelTicket = (state = {
    inProgress: false,
    value: {},
    dialog: false,
    id: '',
}, action) => {
    switch (action.type) {
    case TICKET_CANCEL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKET_CANCEL_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TICKET_CANCEL_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case TICKET_CANCEL_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
            id: action.id,
        };
    case TICKET_CANCEL_DIALOG_HIDE:
        return {
            ...state,
            dialog: false,
            id: '',
        };
    default:
        return state;
    }
};

const profileImage = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROFILE_IMAGE_IN_PROGRESS:
    case UPDATE_PROFILE_IMAGE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROFILE_IMAGE_SUCCESS:
    case UPDATE_PROFILE_IMAGE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROFILE_IMAGE_ERROR:
    case UPDATE_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const ticketDownload = (state = {
    inProgress: false,
    id: '',
}, action) => {
    switch (action.type) {
    case TICKET_DOWNLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            id: action.id,
        };
    case TICKET_DOWNLOAD_SUCCESS: {
        return {
            ...state,
            inProgress: false,
        };
    }
    case TICKET_DOWNLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const stats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const qrCodes = (state = {
    inProgress: false,
    images: [],
}, action) => {
    switch (action.type) {
    case QR_CODE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QR_CODE_FETCH_SUCCESS: {
        const array = [...state.images];
        const filter = array.filter((val) => val.id && action.value && action.value.id && action.value.id === val.id);
        if (filter && filter.length) {
            return {
                ...state,
                inProgress: false,
            };
        }

        array.push(action.value);
        return {
            ...state,
            inProgress: false,
            images: [...array],
        };
    }
    case QR_CODE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    updateName,
    updateNameValue,
    profileDetails,
    orders,
    tickets,
    cancelTicket,
    profileImage,
    ticketDownload,
    stats,
    qrCodes,
});
