import React from 'react';
import './index.css';
import ReCAPTCHA from 'react-google-recaptcha';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { verifyCaptcha } from '../../actions/captcha';

const recaptchaRef = React.createRef();

const Captcha = (props) => {
    const onChange = (value) => {
        props.verifyCaptcha({
            token: value,
        });
    };

    return (
        <div className="captcha">
            <form onSubmit={() => {
                recaptchaRef.current.execute();
            }}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}/>
            </form>
        </div>
    );
};

Captcha.propTypes = {
    captcha: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    verifyCaptcha: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        captcha: state.captcha.value,
        inProgress: state.captcha.inProgress,
    };
};

const actionsToProps = {
    verifyCaptcha,
};

export default connect(stateToProps, actionsToProps)(Captcha);
