import Axios from 'axios';
import { CAPTCHA_VERIFY_URL } from '../constants/url';
import {
    CAPTCHA_VERIFY_CLEAR,
    CAPTCHA_VERIFY_ERROR,
    CAPTCHA_VERIFY_IN_PROGRESS,
    CAPTCHA_VERIFY_SUCCESS,
} from '../constants/captcha';

const verifyCaptchaInProgress = () => {
    return {
        type: CAPTCHA_VERIFY_IN_PROGRESS,
    };
};

const verifyCaptchaSuccess = (value) => {
    return {
        type: CAPTCHA_VERIFY_SUCCESS,
        value,
    };
};

const verifyCaptchaError = (message) => {
    return {
        type: CAPTCHA_VERIFY_ERROR,
        message,
    };
};

export const verifyCaptcha = (data) => (dispatch) => {
    dispatch(verifyCaptchaInProgress());

    Axios.post(CAPTCHA_VERIFY_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(verifyCaptchaSuccess(res && res.data));
        })
        .catch((error) => {
            dispatch(verifyCaptchaError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const clearVerifyCaptcha = () => {
    return {
        type: CAPTCHA_VERIFY_CLEAR,
    };
};
