import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import account from './account';
import buyTicket from './buyTicket';
import myAccount from './myAccount';
import signIn from './signIn';
import navBar from './navBar';
import captcha from './captcha';

export default combineReducers({
    language,
    snackbar,
    account,
    buyTicket,
    myAccount,
    signIn,
    navBar,
    captcha,
});
