import React from 'react';
import { Button, Popover } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../../components/WithRouter';
import { fetchSupport, hideSupportPopover, showSupportConfirmDialog } from '../../../actions/buyTicket';
import './index.css';
import NameTextField from './NameTextField';
import EmailTextField from './EmailTextFIeld';
import MessageTextField from './MessageTextField';
import variables from '../../../utils/variables';
import PurposeSelectField from './PurposeSelectField';
import { showMessage } from '../../../actions/snackbar';
import CircularProgress from '../../../components/CircularProgress';
import Captcha from '../../Captcha';
import { clearVerifyCaptcha } from '../../../actions/captcha';

const SupportPopover = (props) => {
    const handleClose = () => {
        props.onClose();
        props.clearVerifyCaptcha();
    };

    const handleClick = () => {
        if (props.email && props.message && props.name && props.purpose) {
            const data = {
                name: props.name,
                emailAddress: props.email,
                purpose: props.purpose,
                description: props.message,
            };
            props.fetchSupport(data, (cb) => {
                if (cb) {
                    props.showSupportConfirmDialog();
                    handleClose();
                }
            });
        } else {
            props.showMessage('Please fill all the fields');
        }
    };

    const disable = props.email !== '' && props.emailValid && props.message !== '' &&
        props.messageValid && props.name && props.purpose !== 'none';

    return (
        <>
            <Popover
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className="support_popover"
                open={props.open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}>
                <div className="support_details">
                    <h2>{variables[props.lang]['get_in_touch']}</h2>
                    <div className="support_fields">
                        <NameTextField/>
                        <EmailTextField/>
                        <PurposeSelectField/>
                        <div className="message_field">
                            <MessageTextField/>
                            <p className="message_info">
                                {props.message && props.message.length > 0 ? props.message.length : 0} / 150
                            </p>
                        </div>
                    </div>
                    <Captcha/>
                    {props.inProgress
                        ? <Button
                            className="submit"
                            disabled={!disable || (props.captcha && !props.captcha.success) || props.captchaInProgress}>
                            <CircularProgress/>
                            Submit
                        </Button>
                        : <Button
                            className="submit"
                            disabled={!disable || (props.captcha && !props.captcha.success) || props.captchaInProgress}
                            onClick={handleClick}>
                            Submit
                        </Button>}
                </div>
            </Popover>
        </>
    );
};

SupportPopover.propTypes = {
    captcha: PropTypes.object.isRequired,
    captchaInProgress: PropTypes.bool.isRequired,
    clearVerifyCaptcha: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    emailValid: PropTypes.bool.isRequired,
    fetchSupport: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    messageValid: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    purpose: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }).isRequired,
    showMessage: PropTypes.func.isRequired,
    showSupportConfirmDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        captcha: state.captcha.value,
        captchaInProgress: state.captcha.inProgress,
        lang: state.language,
        open: state.buyTicket.supportPopover.open,
        anchorEl: state.buyTicket.supportPopover.anchorEl,
        name: state.buyTicket.supportName,
        message: state.buyTicket.supportMessage.value,
        messageValid: state.buyTicket.supportMessage.valid,
        email: state.buyTicket.supportEmail.value,
        emailValid: state.buyTicket.supportEmail.valid,
        purpose: state.buyTicket.supportPurpose,
        inProgress: state.buyTicket.support.inProgress,
    };
};

const actionToProps = {
    clearVerifyCaptcha,
    onClose: hideSupportPopover,
    fetchSupport,
    showMessage,
    showSupportConfirmDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(SupportPopover));
