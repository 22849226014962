import { combineReducers } from 'redux';
import {
    BUY_TICKETS_EXPANSION_SET,
    CLEAR_CREATE_ORDER,
    CONFIRM_DIALOG_HIDE,
    CONFIRM_DIALOG_SHOW,
    CONFIRM_DIALOG_SUCCESS_SET,
    CONFIRM_SUPPORT_DIALOG_HIDE,
    CONFIRM_SUPPORT_DIALOG_SHOW,
    COUPON_CODE_DATA_FETCH_ERROR,
    COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    COUPON_CODE_DATA_FETCH_SUCCESS,
    COUPON_CODE_REMOVE,
    COUPON_CODE_SET,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_IN_PROGRESS,
    CREATE_ORDER_SUCCESS,
    EMAIL_ADDRESS_SET,
    FETCH_TICKET_TYPE_ERROR,
    FETCH_TICKET_TYPE_IN_PROGRESS,
    FETCH_TICKET_TYPE_SUCCESS,
    FULL_NAME_SET,
    MY_ORDER_FETCH_ERROR,
    MY_ORDER_FETCH_IN_PROGRESS,
    MY_ORDER_FETCH_SUCCESS,
    NO_OF_TICKETS_SET,
    OTP_VALUE_SET,
    OTP_VERIFY_SET,
    PAYMENT_METHOD_SET,
    STRIP_SECRET_FETCH_ERROR,
    STRIP_SECRET_FETCH_IN_PROGRESS,
    STRIP_SECRET_FETCH_SUCCESS,
    SUPPORT_EMAIL_SET,
    SUPPORT_ERROR,
    SUPPORT_IN_PROGRESS,
    SUPPORT_MESSAGE_SET,
    SUPPORT_NAME_SET,
    SUPPORT_POPOVER_HIDE,
    SUPPORT_POPOVER_SHOW,
    SUPPORT_PURPOSE_SET,
    SUPPORT_SUCCESS,
    TICKETS_DATA_SET,
    TIME_OUT_DIALOG_HIDE,
    TIME_OUT_DIALOG_SHOW,
} from '../constants/buyTicket';
import { PROFILE_DETAILS_FETCH_SUCCESS } from '../constants/myAccount';
import { DISCONNECT_SET } from '../constants/wallet';

const noOfTickets = (state = {
    count: 1,
    data: {},
    valid: true,
}, action) => {
    switch (action.type) {
    case NO_OF_TICKETS_SET:
        return {
            ...state,
            count: action.value,
        };
    case TICKETS_DATA_SET:
        return {
            ...state,
            data: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_ORDER:
        return {
            count: 1,
            data: {},
            valid: true,
        };
    default:
        return state;
    }
};

const couponCode = (state = {
    value: '',
    inProgress: false,
    data: {},
}, action) => {
    switch (action.type) {
    case COUPON_CODE_SET:
        return {
            ...state,
            value: action.value,
        };
    case COUPON_CODE_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COUPON_CODE_DATA_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            data: action.value,
        };
    case COUPON_CODE_DATA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_CREATE_ORDER:
    case COUPON_CODE_REMOVE:
        return {
            ...state,
            value: '',
            data: {},
        };
    default:
        return state;
    }
};

const ticketsExpansion = (state = 'info', action) => {
    switch (action.type) {
    case BUY_TICKETS_EXPANSION_SET:
        return action.value;
    case CLEAR_CREATE_ORDER:
        return 'info';

    default:
        return state;
    }
};

const fullName = (state = '', action) => {
    switch (action.type) {
    case FULL_NAME_SET:
        return action.value;
    default:
        return state;
    }
};

const emailAddress = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case EMAIL_ADDRESS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.email_address ? action.value.email_address : '',
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const otp = (state = '', action) => {
    switch (action.type) {
    case OTP_VALUE_SET:
        return action.value;

    default:
        return state;
    }
};

const otpVerify = (state = false, action) => {
    switch (action.type) {
    case OTP_VERIFY_SET:
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return true;
    case DISCONNECT_SET:
        return false;

    default:
        return state;
    }
};

const paymentMethod = (state = 'keplr', action) => {
    switch (action.type) {
    case PAYMENT_METHOD_SET:
        return action.value;

    default:
        return state;
    }
};

const confirmDialog = (state = {
    open: false,
    success: false,
}, action) => {
    switch (action.type) {
    case CONFIRM_DIALOG_SHOW:
        return {
            ...state,
            open: true,
        };
    case CONFIRM_DIALOG_HIDE:
        return {
            open: false,
            success: false,
        };
    case CONFIRM_DIALOG_SUCCESS_SET:
        return {
            ...state,
            success: action.value,
        };

    default:
        return state;
    }
};

const order = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CREATE_ORDER_IN_PROGRESS:
    case MY_ORDER_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CREATE_ORDER_SUCCESS:
    case MY_ORDER_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case CREATE_ORDER_ERROR:
    case MY_ORDER_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_CREATE_ORDER:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const ticketType = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case FETCH_TICKET_TYPE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_TICKET_TYPE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case FETCH_TICKET_TYPE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const timeOutDialog = (state = false, action) => {
    switch (action.type) {
    case TIME_OUT_DIALOG_SHOW:
        return true;
    case TIME_OUT_DIALOG_HIDE:
        return false;

    default:
        return state;
    }
};

const stripeSecret = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STRIP_SECRET_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STRIP_SECRET_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case STRIP_SECRET_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const supportPopover = (state = {
    anchorEl: null,
    open: false,
}, action) => {
    switch (action.type) {
    case SUPPORT_POPOVER_SHOW:
        return {
            anchorEl: action.anchorEl,
            open: true,
        };
    case SUPPORT_POPOVER_HIDE:
        return {
            anchorEl: null,
            open: false,
        };

    default:
        return state;
    }
};

const supportConfirmDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case CONFIRM_SUPPORT_DIALOG_SHOW:
        return {
            open: true,
        };
    case CONFIRM_SUPPORT_DIALOG_HIDE:
        return {
            open: false,
        };

    default:
        return state;
    }
};

const supportName = (state = '', action) => {
    switch (action.type) {
    case SUPPORT_NAME_SET:
        return action.value;
    case SUPPORT_POPOVER_HIDE:
    case SUPPORT_SUCCESS:
        return '';

    default:
        return state;
    }
};

const supportEmail = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case SUPPORT_EMAIL_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SUPPORT_POPOVER_HIDE:
    case SUPPORT_SUCCESS:
        return {
            value: '',
            valid: true,
        };

    default:
        return state;
    }
};

const supportPurpose = (state = 'none', action) => {
    switch (action.type) {
    case SUPPORT_PURPOSE_SET:
        return action.value;
    case SUPPORT_POPOVER_HIDE:
    case SUPPORT_SUCCESS:
        return 'none';

    default:
        return state;
    }
};

const supportMessage = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case SUPPORT_MESSAGE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SUPPORT_POPOVER_HIDE:
    case SUPPORT_SUCCESS:
        return {
            value: '',
            valid: true,
        };

    default:
        return state;
    }
};

const support = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SUPPORT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SUPPORT_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case SUPPORT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    noOfTickets,
    couponCode,
    ticketsExpansion,
    fullName,
    emailAddress,
    otp,
    otpVerify,
    paymentMethod,
    confirmDialog,
    order,
    ticketType,
    timeOutDialog,
    stripeSecret,
    supportPopover,
    supportConfirmDialog,
    supportName,
    supportEmail,
    supportPurpose,
    supportMessage,
    support,
});
