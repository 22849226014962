import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { setSupportEmail } from '../../../actions/buyTicket';
import { isValidEmail } from '../../../utils/validation';

const EmailTextField = (props) => {
    const handleChange = (value) => {
        const valid = isValidEmail(value);
        props.onChange(value, valid);
    };

    return (
        <TextField
            className={!props.valid ? 'email_error' : ''}
            error={!props.valid}
            id="email_address"
            name="email_address"
            placeholder={variables[props.lang].email}
            type="email"
            value={props.value}
            onChange={handleChange}/>
    );
};

EmailTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        valid: state.buyTicket.supportEmail.valid,
        value: state.buyTicket.supportEmail.value,
    };
};

const actionsToProps = {
    onChange: setSupportEmail,
};

export default connect(stateToProps, actionsToProps)(EmailTextField);
