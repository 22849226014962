import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import withRouter from '../../components/WithRouter';
import variables from '../../utils/variables';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import logo from '../../assets/nav_logo.svg';
import ClassNames from 'classnames';
import Icon from '../../components/Icon';
import { hideSideBar, showProfilePopover } from '../../actions/navBar';
import ExpansionButton from './ExpansionButton';
import { showSignInDialog } from '../../actions/signIn';
import SignIn from '../SignIn';
import { fetchProfileDetails, fetchProfileImage, fetchStats } from '../../actions/myAccount';
import { clearCreateOrder, setTicketsData } from '../../actions/buyTicket';
import { fetchBalance } from '../../actions/account/BCDetails';
import { ReactComponent as DownArrow } from '../../assets/all/down-arrow.svg';
import ProfilePopover from './ProfilePopover';

class NavBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            activeState: '/buyTickets',
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
    }

    componentDidMount () {
        const route = this.props.router.location && this.props.router.location.pathname.split('/') &&
            this.props.router.location.pathname.split('/')[1];
        this.setState({ activeState: route });
        if (this.props.profileDetails && !Object.keys(this.props.profileDetails).length &&
            !this.props.profileDetailsInProgress && localStorage.getItem('acToken_flix_fest_22')) {
            this.props.fetchProfileDetails((result) => {
                if (result && result.bc_account && result.bc_account.address) {
                    this.props.fetchBalance(result.bc_account.address);
                }
            });
            this.props.fetchStats();
            this.props.fetchProfileImage();
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                const route = this.props.router.location && this.props.router.location.pathname.split('/') &&
                    this.props.router.location.pathname.split('/')[1];
                this.setState({ activeState: route });
                if (this.props.profileDetails && !Object.keys(this.props.profileDetails).length &&
                    !this.props.profileDetailsInProgress && localStorage.getItem('acToken_flix_fest_22')) {
                    this.props.fetchProfileDetails((result) => {
                        if (result && result.bc_account && result.bc_account.address) {
                            this.props.fetchBalance(result.bc_account.address);
                        }
                    });
                    this.props.fetchStats();
                    this.props.fetchProfileImage();
                }
            }, 400);
        }
    }

    handleClick (item) {
        this.setState({
            activeState: item,
        });

        if (this.props.show) {
            this.props.handleClose();
            document.body.style.overflow = null;
        }

        if (this.props.router.location && this.props.router.location.pathname.indexOf('payment') > -1) {
            this.props.clearCreateOrder();
            this.props.setTicketsData({
                ticket1: {
                    name: '',
                    email: '',
                    email_valid: true,
                    name_valid: true,
                },
            });
        }

        this.props.router.navigate(`/${item}`);
    }

    handleClose () {
        if (this.props.show) {
            this.props.handleClose();
            document.body.style.overflow = null;
        }
    }

    handleSignIn () {
        if (this.props.show) {
            this.props.handleClose();
            document.body.style.overflow = null;
        }

        this.props.showSignInDialog();
    }

    render () {
        const { activeState } = this.state;

        return (
            <div className="nav_bar">
                <div
                    className="logo_button"
                    onClick={() => this.props.router.navigate('/buyTickets')}>
                    <img alt="logo" src={logo}/>
                </div>
                <ExpansionButton/>
                <div className={ClassNames('right_nav', this.props.show ? 'show' : '')}>
                    <div className="back_button" onClick={this.handleClose}>
                        <Icon className="cross" icon="cross"/>
                    </div>
                    <Button
                        className={activeState === 'buyTickets' ? 'buy_tickets active_nav' : 'buy_tickets'}
                        onClick={() => this.handleClick('buyTickets')}>
                        {variables[this.props.lang]['buy_tickets']}
                    </Button>
                    {this.props.profileDetails && Object.keys(this.props.profileDetails).length
                        ? <Button
                            className="profile_button"
                            onClick={this.props.showProfilePopover}>
                            {this.props.profileImage
                                ? <img alt="profile" className="profile_image" src={this.props.profileImage}/>
                                : null}
                            <div className="profile_details">
                                <span>{this.props.profileDetails && this.props.profileDetails.name
                                    ? this.props.profileDetails.name : 'Full Name'}</span>
                                <span title={this.props.profileDetails && this.props.profileDetails.email_address}>
                                    {this.props.profileDetails && this.props.profileDetails.email_address}
                                </span>
                            </div>
                            <DownArrow/>
                        </Button>
                        : <Button
                            className={activeState === 'signIn' ? 'sign_in active_nav' : 'sign_in'}
                            onClick={this.handleSignIn}>
                            {variables[this.props.lang]['sign_in']}
                        </Button>}
                </div>
                <SignIn/>
                <ProfilePopover/>
            </div>
        );
    }
}

NavBar.propTypes = {
    clearCreateOrder: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    fetchStats: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileDetailsInProgress: PropTypes.bool.isRequired,
    profileImage: PropTypes.any.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    setTicketsData: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    showProfilePopover: PropTypes.func.isRequired,
    showSignInDialog: PropTypes.func.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.signIn.token.value,
        lang: state.language,
        show: state.navBar.show,
        profileDetails: state.myAccount.profileDetails.value,
        profileDetailsInProgress: state.myAccount.profileDetails.inProgress,
        profileImage: state.myAccount.profileImage.value,
    };
};

const actionToProps = {
    clearCreateOrder,
    fetchBalance,
    fetchProfileDetails,
    fetchProfileImage,
    fetchStats,
    handleClose: hideSideBar,
    setTicketsData,
    showSignInDialog,
    showProfilePopover,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
