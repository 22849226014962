import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { setSupportMessage } from '../../../actions/buyTicket';
import { unicodeToChar } from '../../../utils/unicode';

const MessageTextField = (props) => {
    const handleChange = (value) => {
        // eslint-disable-next-line no-empty
        if (value.length === 150 || value.length > 151) {
        } else {
            props.onChange(value, true);
        }
    };
    return (
        <TextField
            multiline
            className={!props.valid ? 'email_error text_area' : 'text_area'}
            error={!props.valid}
            id="description"
            name="description"
            placeholder={variables[props.lang].message}
            value={unicodeToChar(props.value)}
            onChange={handleChange}/>
    );
};

MessageTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.buyTicket.supportMessage.value,
        valid: state.buyTicket.supportMessage.valid,
    };
};

const actionsToProps = {
    onChange: setSupportMessage,
};

export default connect(stateToProps, actionsToProps)(MessageTextField);
